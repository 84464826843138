@import '../../assets/scss/abstract/abstract';

.container {
  list-style: none;
  margin: 0;
  padding: 0;
}

.notification {
  margin: var(--yps-default-space) 0;
  padding: 7px 15px;
  background-color: var(--yps-primary-color);
  border-radius: 5px;
  color: var(--yps-bg-color);
  display: grid;
  grid-template-columns: auto 20px;
  column-gap: var(--yps-default-space);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, .4);

  &__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  &__title {
    margin: 0;
    font-weight: 600;
    margin-bottom: var(--yps-default-space);
    margin-bottom: 5px;
    font-size: 16px;
  }

  &__message {
    margin: 0;
  }

  &__close {
    margin-top: 3px;
    color: white;
  }

  &--danger {
    background-color: var(--yps-danger-color);
  }

  &--warning {
    background-color: var(--yps-warn-color);
  }

  &--large {
    padding: 50px;
  }
}