@mixin input-label() {
  color: var(--yps-text-color-extra-light);
  font-size: 0.85em;
  text-transform: uppercase;
}

@mixin input-field() {
  appearance: none;
  border-radius: 0;
  border: var(--yps-input-border-width) solid var(--yps-input-border-color);
  color: var(--yps-text-color-dark);
  font-weight: 600;
  font-size: 1em;
  text-align: left;
  line-height: 1.15em;
  outline-color: var(--yps-input-outline-color);
  background-color: var(--yps-input-bg-color);
  font-family: 'Open Sans', Arial, sans-serif;

  &::placeholder {
    color: var(--yps-text-color-extra-light);
    font-weight: 400;
  }

  &--focus {
    filter: drop-shadow(0 0px 10px var(--yps-input-focus-shadow-color));
  }

  &--error {
    border-color: var(--yps-danger-color);
  }
}
