@import "../../assets/scss/abstract/abstract";

.pageContainer {
  font-weight: 400;
  font-size: 13px;
  min-height: 570px;
  min-width: 320px;
  background-color: var(--yps-bg-color);
  color: var(--yps-text-color);


  @include mobile {
    padding: 0;
  }

  * {
    box-sizing: border-box;
  }
}

.popupWrapper {
  color: var(--yps-text-color);
}