.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}


.slideUp-enter {
  opacity: 0;
  transform: translateY(7px);
}
.slideUp-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.slideUp-exit {
  opacity: 1;
}
.slideUp-exit-active {
  opacity: 0;
  transform: translateY(7px);
  transition: opacity 300ms, transform 300ms;
}

.slideDown-enter {
  opacity: 0;
  transform: translateY(-7px);
}
.slideDown-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}
.slideDown-exit {
  opacity: 1;
}
.slideDown-exit-active {
  opacity: 0;
  transform: translateY(-7px);
  transition: opacity 300ms, transform 300ms;
}


.tabsSlide-enter {
  opacity: 0;
  transform: translateX(50%);
}
.tabsSlide-enter-active {
  opacity: 1;
  transform: translateX(0%);
}
.tabsSlide-exit {
  opacity: 1;
  transform: translateX(0%);
}
.tabsSlide-exit-active {
  opacity: 0;
  transform: translateX(-50%);
}
.tabsSlide-enter-active,
.tabsSlide-exit-active {
  transition: opacity 200ms, transform 200ms;
}
