@use "sass:math";
@import "./abstract/abstract";
@import "./typography";
@import "./animations";
@import "./theme";

#__next {
  font-family: "Open Sans", Arial, sans-serif;
  font-weight: 400;
  font-size: 13px;
  color: var(--yps-text-color);

  * {
    box-sizing: border-box;
  }
}

html {
  height: 100%;
  margin: 0;
}

body {
  height: 100%;
  margin: 0;
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  &.in-iframe {
    min-height: auto;
    height: auto;

    #yps_container {
      margin: 1px;
    }
  }

  &.inCompareMode {
    .header {
      &__page-header {
        display: none;
      }

      &__agent-header {
        display: none;
      }

      &__custom-components {
        display: none;
      }
    }
    .searchform {
      &__outerContainer {
        display: none;
      }
    }

    .index {
      &__custom-components {
        display: none;
      }
    }

    #yps_container {
      padding-top: 200px !important;
    }
  }

  &.printOffer {
    .forcePrint {
      display: block !important;
    }

    .noPrint {
      display: none;
    }
    .offer {
      border: 0 !important;
      font-family: "Open Sans", Arial, sans-serif;
      font-weight: 400;
      font-size: 13px;
      color: var(--yps-text-color);
    }
  }
}

@media print {
  @page {
    margin-top: 10mm;
    padding-top: 10mm;
    margin-bottom: 10mm;
    padding-bottom: 10mm;
    margin-left: 10mm;
    padding-left: 10mm;
    margin-right: 10mm;
    padding-right: 10mm;
  }
}
/* --------------------------------------------------------------- OVERWRITE EXTERNAL MODULES --- */

.__react_component_tooltip.show {
  opacity: 1 !important;
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.6));
  padding: math.div(var(--yps-default-space), 2) var(--yps-default-space);
}

h2 {
  font-size: 1.35em;
  font-weight: 500;
}

.dynamicBackgroundWrapper {
  .negativeMarginWithDynamicBg {
    margin: 0 -20px !important;

    &.offerlistOuterContainer {
      @include mobile {
        padding: 0 20px;
      }
    }
  }
}

:root {
  --rdp-cell-size: 40px;
}

.rdp {
  margin: 1em;
}

@media (max-width: 767px) {
  .rdp {
    &--month {
      display: table;
    }
  }
}

/* Hide elements for devices that are not screen readers */
.rdp-vhidden {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  position: absolute !important;
  top: 0;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
}

/* Buttons */
.rdp-button_reset {
  appearance: none;
  position: relative;
  margin: 0;
  padding: 0;
  cursor: default;
  color: inherit;
  outline: none;
  background: none;
  font: inherit;

  -moz-appearance: none;
  -webkit-appearance: none;
}

.rdp-button {
  border: 2px solid transparent;
}

.rdp-button[aria-disabled="true"] {
  opacity: 0.25;
  pointer-events: none;
}

.rdp-button:not([aria-disabled="true"]) {
  cursor: pointer;
}

.rdp-button:focus,
.rdp-button:active {
  color: inherit;
  border: var(--yps-calendar-focus-border-color);
  background-color: var(--yps-calendar-focus-bg-color);
}

.rdp-button:hover:not([aria-disabled="true"]) {
  background-color: var(--yps-primary-color);
  color: #fff;
}

.rdp-months {
  display: flex;
}

.rdp-month {
  margin: 0 1em;
  @include mobile {
    width: 100%;
  }
}

.rdp-month:first-child {
  margin-left: 0;
}

.rdp-month:last-child {
  margin-right: 0;
}

.rdp-table {
  margin: 0;
  max-width: calc(var(--rdp-cell-size) * 7);
  border-collapse: collapse;
  @include mobile {
    width: 100%;
    max-width: 100%;
  }
}

.rdp-with_weeknumber .rdp-table {
  max-width: calc(var(--rdp-cell-size) * 8);
  border-collapse: collapse;

  @include mobile {
    max-width: 100%;
  }
}

.rdp-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  text-align: left;

  @include mobile {
    color: #fff;
  }
}

.rdp-multiple_months .rdp-caption {
  position: relative;
  display: block;
  text-align: center;
}

.rdp-caption_dropdowns {
  position: relative;
  display: inline-flex;
}

.rdp-caption_label {
  position: relative;
  z-index: 1;
  display: inline-flex;
  align-items: center;
  margin: 0;
  padding: 0 0.25em;
  white-space: nowrap;
  color: currentColor;
  border: 0;
  border: 2px solid transparent;
  font-family: inherit;
  font-size: 140%;
  font-weight: bold;
}

.rdp-nav {
  white-space: nowrap;
}

.rdp-multiple_months .rdp-caption_start .rdp-nav {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.rdp-multiple_months .rdp-caption_end .rdp-nav {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.rdp-nav_button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  padding: 0.25em;
  border-radius: 100%;
}

/* ---------- */
/* Dropdowns  */
/* ---------- */

.rdp-dropdown_year,
.rdp-dropdown_month {
  position: relative;
  display: inline-flex;
  align-items: center;
}

.rdp-dropdown {
  appearance: none;
  position: absolute;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: inherit;
  opacity: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.rdp-dropdown[disabled] {
  opacity: unset;
  color: unset;
}

.rdp-dropdown:focus:not([disabled]) + .rdp-caption_label,
.rdp-dropdown:active:not([disabled]) + .rdp-caption_label {
  border: var(--rdp-outline);
  border-radius: 6px;
  background-color: var(--yps-primary-color);
}

.rdp-dropdown_icon {
  margin: 0 0 0 5px;
}

.rdp-head {
  border: 0;
}

.rdp-head_row,
.rdp-row {
  height: 100%;
}

.rdp-head_cell {
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 700;
  text-align: center;
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  @include mobile {
    color: #fff;
  }
}

.rdp-tbody {
  border: 0;
}

.rdp-tfoot {
  margin: 0.5em;
}

.rdp-cell {
  width: var(--rdp-cell-size);
  height: 100%;
  height: var(--rdp-cell-size);
  padding: 0;
  text-align: center;
}

.rdp-weeknumber {
  font-size: 0.75em;
}

.rdp-weeknumber,
.rdp-day {
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: var(--rdp-cell-size);
  max-width: var(--rdp-cell-size);
  height: var(--rdp-cell-size);
  color: var(--yps-text-color);
  @include mobile {
    color: #fff;
    width: 100%;
    max-width: 100%;
  }
  margin: 0;
  border: 2px solid transparent;
}

.rdp-day_today:not(.rdp-day_outside) {
  font-weight: bold;
}

.rdp-day_selected {
  border-radius: 25%;
}

.rdp-day_selected:not([aria-disabled="true"]),
.rdp-day_selected:focus:not([aria-disabled="true"]),
.rdp-day_selected:active:not([aria-disabled="true"]),
.rdp-day_selected:hover:not([aria-disabled="true"]) {
  color: var(--yps-calendar-active-color);
  background-color: var(--yps-calendar-active-bg-color);
}

.rdp-day_range_middle {
  background-color: #dadada !important;
  color: var(--yps-text-color) !important;
}

.rdp:not([dir="rtl"]) .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp:not([dir="rtl"]) .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir="rtl"] .rdp-day_range_start:not(.rdp-day_range_end) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.rdp[dir="rtl"] .rdp-day_range_end:not(.rdp-day_range_start) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.rdp-day_range_middle {
  border-radius: 0;
}

.rdp-day_selected {
  &:not([disabled]):not(.rdp-day_range_middle):not(.rdp-day_range_start):not(.rdp-day_range_end) {
    background: #dadada;
    color: var(--yps-text-color);
    border-radius: 0;
  }
}

.icon {
  &__levelSuccess {
    color: var(--yps-success-color);
  }
  &__levelWarning {
    color: var(--yps-warn-color);
  }
  &__levelDanger {
    color: var(--yps-danger-color);
  }
}


.pigeon-attribution {
  display: none;
}


.swiper-button-prev,
.swiper-button-next {
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid var(--yps-primary-color);
  width: 40px !important;
  height: 40px !important;
}

.inlineDisplay {
  display: inline;
}


@media (min-width: 1025px) {
  .searchform_locationWrapper {
    .pickupSuggester {
      .suggester_innerContainer {
        border-radius: var(--yps-box-radius-desktop-search-strip-suggester) !important;
      }
    }
  }
  .searchform_searchButton {
    border-radius: var(--yps-box-radius-desktop-search-strip-button) !important;
  }
}

@include tablet {
  .searchform_locationWrapper {
    .pickupSuggester {
      .suggester_innerContainer {
        border-radius: var(--yps-box-radius-tablet-search-strip-suggester) !important;
      }
    }
  }

  .returnTimepicker {
    border-radius: var(--yps-box-radius-tablet-search-strip-timepicker) !important;
  }

  .searchform_searchButton {
    border-radius: var(--yps-box-radius-tablet-search-strip-button) !important;
  }
}

@include mobile {
  .searchform_locationWrapper {
    .pickupSuggester {
      .suggester_innerContainer {
        border-radius: var(--yps-box-radius-mobile-search-strip-suggester) !important;
      }
    }
  }
  .searchform_searchButton {
    border-radius: var(--yps-box-radius-mobile-search-strip-button) !important;
  }
}

.forms {
  &_row {
    &--small_row_gap {
      row-gap: 10px !important;
    }
  }
}

.break-flex-row {
  flex-basis: 100% !important;
  height: 0;
}

.breakFlex {
  flex-basis: 100% !important;
}