@import "../../assets/scss/abstract/abstract";

.pageContainer {
  font-weight: 400;
  font-size: 14px;
  min-height: 570px;

  @include mobile {
    padding: 10px;
  }


  * {
    box-sizing: border-box;
  }
}