@font-face {
  font-family: "cars";
  src: url("/fonts/cars.woff?52295006") format("truetype");
  font-weight: normal;
  font-style: normal;
}

[class^="cars-icon-"]:before,
[class*=" cars-icon-"]:before {
  font-family: "cars" !important;
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  margin-right: 0.2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: 0.2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.cars-icon-emo-unhappy:before { content: '\e800'; } /* '' */
.cars-icon-emo-happy:before { content: '\e801'; } /* '' */
.cars-icon-check:before { content: '\e802'; } /* '' */
.cars-icon-chevron-down-thin:before { content: '\e803'; } /* '' */
.cars-icon-chevron-left-thin:before { content: '\e804'; } /* '' */
.cars-icon-chevron-right-thin:before { content: '\e805'; } /* '' */
.cars-icon-chevron-up-thin:before { content: '\e806'; } /* '' */
.cars-icon-user:before { content: '\e807'; } /* '' */
.cars-icon-user2:before { content: '\e808'; } /* '' */
.cars-icon-emo-wink:before { content: '\e809'; } /* '' */
.cars-icon-heart:before { content: '\e80a'; } /* '' */
.cars-icon-heart-empty:before { content: '\e80b'; } /* '' */
.cars-icon-pencil:before { content: '\e80c'; } /* '' */
.cars-icon-distance:before { content: '\e80d'; } /* '' */
.cars-icon-gasoline-pump-2:before { content: '\e80e'; } /* '' */
.cars-icon-pin-dropoff:before { content: '\e80f'; } /* '' */
.cars-icon-money:before { content: '\e810'; } /* '' */
.cars-icon-steering-wheel:before { content: '\e811'; } /* '' */
.cars-icon-map-1:before { content: '\e812'; } /* '' */
.cars-icon-user-1:before { content: '\e813'; } /* '' */
.cars-icon-chevron-down:before { content: '\e824'; } /* '' */
.cars-icon-arrow-down:before { content: '\e825'; } /* '' */
.cars-icon-chevron-up:before { content: '\e826'; } /* '' */
.cars-icon-arrow-up:before { content: '\e827'; } /* '' */
.cars-icon-chevron-left:before { content: '\e828'; } /* '' */
.cars-icon-chevron-right:before { content: '\e829'; } /* '' */
.cars-icon-arrow-left:before { content: '\e82a'; } /* '' */
.cars-icon-arrow-right:before { content: '\e82b'; } /* '' */
.cars-icon-tick:before { content: '\e82c'; } /* '' */
.cars-icon-automatic-gear:before { content: '\e831'; } /* '' */
.cars-icon-awd:before { content: '\e832'; } /* '' */
.cars-icon-loop:before { content: '\e833'; } /* '' */
.cars-icon-manual-gear:before { content: '\e834'; } /* '' */
.cars-icon-nav:before { content: '\e835'; } /* '' */
.cars-icon-tyres:before { content: '\e836'; } /* '' */
.cars-icon-doors:before { content: '\e837'; } /* '' */
.cars-icon-gasoline-pump-3:before { content: '\e838'; } /* '' */
.cars-icon-hand_with_money:before { content: '\e83f'; } /* '' */
.cars-icon-smartphone:before { content: '\e840'; } /* '' */
.cars-icon-dropoff-car:before { content: '\e841'; } /* '' */
.cars-icon-pickup-car:before { content: '\e842'; } /* '' */
.cars-icon-pin-airport:before { content: '\e843'; } /* '' */
.cars-icon-pin-city:before { content: '\e844'; } /* '' */
.cars-icon-cross:before { content: '\e845'; } /* '' */
.cars-icon-pin-pickup:before { content: '\e846'; } /* '' */
.cars-icon-pin-rail:before { content: '\e847'; } /* '' */
.cars-icon-pin-selected:before { content: '\e848'; } /* '' */
.cars-icon-shield:before { content: '\e849'; } /* '' */
.cars-icon-gasoline-pump:before { content: '\e85a'; } /* '' */
.cars-icon-terminal:before { content: '\e85b'; } /* '' */
.cars-icon-shuttle:before { content: '\e85c'; } /* '' */
.cars-icon-exclamation:before { content: '\e871'; } /* '' */
.cars-icon-exclamation-2:before { content: '\e872'; } /* '' */
.cars-icon-circle-2:before { content: '\e873'; } /* '' */
.cars-icon-circle:before { content: '\e874'; } /* '' */
.cars-icon-dot:before { content: '\e875'; } /* '' */
.cars-icon-map:before { content: '\e8bc'; } /* '' */
.cars-icon-suitcase2:before { content: '\e900'; } /* '' */
.cars-icon-person:before { content: '\e901'; } /* '' */
.cars-icon-coins:before { content: '\e902'; } /* '' */
.cars-icon-office:before { content: '\e903'; } /* '' */
.cars-icon-automobile:before { content: '\e904'; } /* '' */
.cars-icon-directions_car:before { content: '\e905'; } /* '' */
.cars-icon-train-station:before { content: '\e906'; } /* '' */
.cars-icon-suitcase:before { content: '\e907'; } /* '' */
.cars-icon-share:before { content: '\e908'; } /* '' */
.cars-icon-print:before { content: '\e909'; } /* '' */
.cars-icon-pickup:before { content: '\e90a'; } /* '' */
.cars-icon-people:before { content: '\e90b'; } /* '' */
.cars-icon-info:before { content: '\e90c'; } /* '' */
.cars-icon-filter:before { content: '\e90d'; } /* '' */
.cars-icon-droppoff:before { content: '\e90e'; } /* '' */
.cars-icon-city:before { content: '\e910'; } /* '' */
.cars-icon-bookmark:before { content: '\e911'; } /* '' */
.cars-icon-b:before { content: '\e912'; } /* '' */
.cars-icon-ac:before { content: '\e913'; } /* '' */
.cars-icon-a:before { content: '\e914'; } /* '' */
.cars-icon-search:before { content: '\e919'; } /* '' */
.cars-icon-airport:before { content: '\e91a'; } /* '' */
.cars-icon-promo-code:before { content: '\e91d'; } /* '' */
.cars-icon-library:before { content: '\e921'; } /* '' */
.cars-icon-location:before { content: '\e947'; } /* '' */
.cars-icon-power-cord:before { content: '\e9b7'; } /* '' */
.cars-icon-share2:before { content: '\ea82'; } /* '' */
.cars-icon-sort:before { content: '\f0dc'; } /* '' */
.cars-icon-user-plus:before { content: '\f234'; } /* '' */
.cars-icon-balance-scale:before { content: '\f24e'; } /* '' */
.cars-icon-user-o:before { content: '\f2c0'; } /* '' */
.cars-icon-add:before { content: '󡠀'; } /* '\e1800' */
.cars-icon-trash-empty:before { content: '󡠁'; } /* '\e1801' */
.cars-icon-clock:before { content: '󨀁'; } /* '\e8001' */
.cars-icon-wifi:before { content: '\f1eb'; } /* '' */
.cars-icon-signal:before { content: '\e800'; } /* '' */
