@import "../../assets/scss/abstract/abstract";

.pageFooter {
  max-width: var(--yps-max-width);
  background: var(--yps-bg-color-footer);
  width: calc(100% - 20px);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include mobile {
    max-width: 100vw;
    flex-wrap: wrap;
  }

  &--enlarge {
    margin-top: 50px;
  }

  &__wrapper {
    max-width: 100%;
    background: var(--yps-bg-color-footer-wrapper);
  }
}

.acceptedPayments {
  @include mobile {
    width: 100%;
    text-align: center;
  }

  &__title {
    text-transform: uppercase;
    color: var(--yps-text-color-extra-light);
    font-size: 12px;
  }

  &__list {
    padding: 0;
    list-style: none;
    margin: var(--yps-default-space) 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20px;

    @include mobile {
      justify-content: space-between;
    }

    img {
      height: 50px;
      filter: grayscale(1);
      opacity: 0.3;
      transition: all 0.3s ease-out;

      @include mobile {
        height: 30px;
      }

      &:hover {
        opacity: 1;
        filter: grayscale(0);
      }
    }
  }
}

.certificates {
  @include mobile {
    width: 100%;
  }

  &__title {
    text-transform: uppercase;
    color: var(--yps-text-color-extra-light);
    font-size: 12px;
    text-align: right;

    @include mobile {
      text-align: center;
    }
  }

  &__list {
    padding: 0;
    list-style: none;
    margin: var(--yps-default-space) 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 20px;

    @include mobile {
      justify-content: space-between;
    }
  }

  &__small {
    .certificates__logo {
      height: 55px;
    }
  }

  &__logo {
    height: 70px;
    filter: grayscale(1);
    opacity: 0.3;
    transition: all 0.3s ease-out;
    transform: translateZ(0);

    &:hover {
      opacity: 1;
      filter: grayscale(0);
    }

    &--reduced-opacity {
      @extend .certificates__logo;
      opacity: 0.6;
    }
  }
}

.stickyContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--yps-border-color-light);
  z-index: 100;

  &__content {
    width: var(--yps-max-width);
    max-width: 100%;
    margin: 0 auto;

    > *:not(:last-child) {
      padding-bottom: 20px;
      margin-bottom: 20px;
      position: relative;

      &:after {
        content: "";
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: var(--yps-border-color);
      }
    }
  }
}
