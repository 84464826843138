$appPrimary: #006dcc;
$appPrimaryLight: lighten($appPrimary, 25%);
$appPrimaryDark: darken($appPrimary, 15%);
$appPriceColor: #c61111;
$appSuccess: #53a653;
$appWarn: #f97e00;
$appDanger: #c61111;
$appBorderColor: #c0c0c0;
$appTextColor: #5a5a5a;

:root {
  --yps-bg-color: #fff;
  --yps-bg-color-secondary: #eeeeee;
  --yps-bg-color-footer: var(--yps-bg-color);
  --yps-bg-color-footer-wrapper: var(--yps-bg-color);

  --yps-text-color: #{$appTextColor};
  --yps-text-color-light: #717171;
  --yps-text-color-extra-light: #b1b1b1;
  --yps-text-color-dark: #343434;
  --yps-text-color-muted: #9a9a9a;

  --yps-general-links-color: #{$appPriceColor};
  --yps-general-links-hover-color: #{$appPriceColor};

  --yps-primary-color: #{$appPrimary};
  --yps-primary-color-dark: #{$appPrimaryDark};

  --yps-border-color: #c0c0c0;
  --yps-border-color-light: #e6e6e6;

  --yps-success-color: #{$appSuccess};
  --yps-success-color-muted: #{rgba($appSuccess, 0.6)};
  --yps-success-color-transparent: #{rgba($appSuccess, 0.2)};
  --yps-warn-color: #{$appWarn};
  --yps-danger-color: #{$appDanger};

  --yps-overlay-bg-color: hsla(0, 0%, 100%, 0.8);
  --yps-overlay-bg-filter: blur(2px);
  --yps-overlay-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
  --yps-overlay-head-color: #{$appTextColor};
  --yps-overlay-mobile-bg-color: #5a5a5a;
  --yps-overlay-mobile-head-color: #{$appTextColor};

  --yps-featured-price: #{$appPriceColor};
  --yps-progress-indicator: var(--yps-primary-color);
  --yps-spinner-color: var(--yps-primary-color);

  // dimensions
  --yps-max-width: 1180px;
  --yps-default-space: 10px;

  // buttons
  --yps-p-button-bg-color: var(--yps-primary-color);
  --yps-p-button-color: #ffffff;
  --yps-p-button-border-color: var(--yps-primary-color);
  --yps-p-button-icon-color: #ffffff;
  --yps-p-button-hover-bg-color: var(--yps-primary-color-dark);
  --yps-p-button-hover-color: #ffffff;
  --yps-p-button-hover-border-color: var(--yps-primary-color-dark);
  --yps-p-button-hover-icon-color: #ffffff;

  --yps-s-button-bg-color: #fff;
  --yps-s-button-color: #{$appTextColor};
  --yps-s-button-icon-color: #{$appTextColor};
  --yps-s-button-border-color: #c0c0c0;
  --yps-s-button-hover-bg-color: #fff;
  --yps-s-button-hover-color: var(--yps-primary-color);
  --yps-s-button-hover-border-color: var(--yps-primary-color);
  --yps-s-button-hover-icon-color: #{$appTextColor};

  --yps-t-button-bg-color: #fff;
  --yps-t-button-color: var(--yps-primary-color);
  --yps-t-button-icon-color: var(--yps-primary-color);
  --yps-t-button-border-color: transparent;
  --yps-t-button-hover-bg-color: #fff;
  --yps-t-button-hover-color: var(--yps-primary-color-dark);
  --yps-t-button-hover-border-color: transparent;
  --yps-t-button-hover-icon-color: var(--yps-primary-color);

  // forms
  --yps-input-color: #{$appTextColor};
  --yps-input-bg-color: #fff;
  --yps-input-icon-color: #dadada;
  --yps-input-border-width: 1px;
  --yps-input-border-color: #{$appBorderColor};
  --yps-input-paddings: 10px 5px;
  --yps-input-outline-color: var(--yps-primary-color);
  --yps-input-focus-shadow-color: #{$appPrimaryLight}60;
  --yps-input-mobile-paddings: 30px 0;

  // infobox
  --yps-infobox-heading-error: #343434;
  --yps-infobox-text-error: #f44336;
  --yps-infobox-icon-error: #f44336;
  --yps-infobox-background-error: transparent;
  --yps-infobox-border-error: #f44336;

  --yps-infobox-heading-info: #343434;
  --yps-infobox-text-info: #{$appTextColor};
  --yps-infobox-icon-info: #006dcc;
  --yps-infobox-background-info: inherit;
  --yps-infobox-border-info: #006dcc;

  --yps-infobox-heading-success: #04aa6d;
  --yps-infobox-text-success: #fff;
  --yps-infobox-icon-success: #fff;
  --yps-infobox-background-success: #04aa6d;
  --yps-infobox-border-success: #04aa6d;

  --yps-infobox-heading-default: #343434;
  --yps-infobox-text-default: #5a5a5a;
  --yps-infobox-icon-default: #5a5a5a;
  --yps-infobox-background-default: #eeeeee;
  --yps-infobox-border-default: #eeeeee;

  --yps-infobox-heading-warning: #ff9800;
  --yps-infobox-text-warning: #{$appTextColor};
  --yps-infobox-icon-warning: #ff9800;
  --yps-infobox-background-warning: inherit;
  --yps-infobox-border-warning: #ff9800;

  --yps-infobox-heading-neutral: inherit;
  --yps-infobox-text-neutral: inherit;
  --yps-infobox-icon-neutral: inherit;
  --yps-infobox-background-neutral: inherit;
  --yps-infobox-border-neutral: #e6e6e6;


  --yps-steps-wrapper-bg-color: var(--yps-bg-color);
  --yps-steps-item-bg-color: var(--yps-bg-color);

  --yps-steps-item-text-color: var(--yps-text-color-extra-light);
  --yps-steps-item-text-highlight-color: var(--yps-primary-color);

  --yps-steps-item-icon-color: var(--yps-text-color-extra-light);
  --yps-steps-item-icon-highlight-color: var(--yps-primary-color);

  --yps-steps-item-icon-border-color: var(--yps-border-color);
  --yps-steps-item-icon-highlight-border-color: var(--yps-primary-color);

  --yps-steps-item-number-color: var(--yps-text-color-extra-light);
  --yps-steps-item-number-highlight-color: var(--yps-primary-color);

  --yps-steps-item-number-border-color: var(--yps-border-color);
  --yps-steps-item-number-highlight-border-color: var(--yps-primary-color);

  // tooltip
  --yps-tooltip-background: #666;
  --yps-tooltip-text: #fff;
  --yps-tooltip-border: #666;

  // offerlist
  --yps-offerlist-mobile-bg-color: #f2f2f2;

  // offer
  --yps-offer-bg-color: #fff;
  --yps-offer-border-width: 1px;
  --yps-offer-border-color: #dddddd;
  --yps-offer-box-shadow: none;
  --yps-offer-inside-paddings: 15px;
  --yps-offer-inside-paddings-tablet: 10px;
  --yps-offer-badge-bg-color: #{$appPrimaryLight};
  --yps-offer-badge-text: #fff;
  --yps-offer-match-type-label-bg-color: var(--yps-primary-color);
  --yps-offer-match-type-label-text-color: #fff;
  --yps-offer-match-type-border-color: var(--yps-primary-color);

  // score
  --yps-score-premium-color: #fff;
  --yps-score-premium-bg-color: #70b670;
  --yps-score-premium-border-color: #70b670;

  --yps-score-basic-color: #5a5a5a;
  --yps-score-basic-bg-color: #cccccc;
  --yps-score-basic-border-color: #cccccc;

  --yps-score-economy-color: #fff;
  --yps-score-economy-bg-color: #ec9d4c;
  --yps-score-economy-border-color: #ec9d4c;

  // others
  --yps-filter-inactive-bg: #eeeeee;
  --yps-filter-inactive-text: #cecece;

  --swiper-theme-color: var(--yps-primary-color);
  --swiper-active-color: var(--yps-primary-color);
  --swiper-navigation-size: 30px !important;

  // discount label
  --yps-discount-label-border-color: var(--yps-border-color);
  --yps-discount-label-bg-color: #f7f7f7;
  --yps-discount-label-code-color: var(--yps-text-color-extra-light);

  // compare
  --yps-compare-mobile-overlay: rgba(0, 0, 0, 0.2);
  --yps-compare-mobile-border: #20A71D;
  --yps-compare-mobile-not-added-icon-background: #333;
  --yps-compare-mobile-not-added-icon-color: #fff;
  --yps-compare-mobile-added-icon-background: #20A71D;
  --yps-compare-mobile-added-icon-color: #fff;

  --yps-box-radius-offer: 0;
  --yps-box-radius-slider-offer: 0;
  --yps-box-radius-sidebar: 0;
  --yps-box-radius-floating-content: var(--yps-box-radius-sidebar);
  --yps-box-radius-booking-boxes: 0;

  --yps-box-radius-desktop-search-strip-suggester: 0;
  --yps-box-radius-desktop-search-strip-button: 0;
  --yps-box-radius-tablet-search-strip-suggester: 0;
  --yps-box-radius-tablet-search-strip-button: 0;
  --yps-box-radius-tablet-search-strip-timepicker: 0;
  --yps-box-radius-mobile-search-strip-button: 0;

  --yps-sidebar-background: #fff;
  --yps-sidebar-border-color: var(--yps-offer-border-color);

  --yps-dropdown-button-icon-color: var(--yps-text-color);


  --yps-suggester-text-color: var(--yps-primary-color);
  --yps-suggester-bg-color: var(--yps-bg-color);
  --yps-suggester-location-icon-color: var(--yps-input-border-color);
  --yps-suggester-location-details-color: var(--yps-text-color-extra-light);


  --yps-suggester-text-color-secondary: var(--yps-primary-color);
  --yps-suggester-bg-color-secondary: var(--yps-bg-color-secondary);
  --yps-suggester-location-icon-color-secondary: var(--yps-input-border-color);
  --yps-suggester-location-details-color-secondary: var(--yps-text-color-extra-light);

  --yps-calendar-active-color: #fff;
  --yps-calendar-active-border-color: var(--yps-primary-color);
  --yps-calendar-active-bg-color: var(--yps-primary-color);

  --yps-calendar-focus-border-color: var(--yps-primary-color);
  --yps-calendar-focus-bg-color: var(--yps-primary-color-dark);
}
