@use "sass:math";
@import "assets/scss/abstract/abstract";

.button {
  margin: 0;
  padding: 0;
  appearance: none;
  cursor: pointer;
  border: 1px solid var(--yps-input-border-color);
  background: none;
  outline: none;
  color: var(--yps-text-color);
  font-weight: 400;
  font-size: 1em;
  line-height: 1.15em;
  padding: 6px 10px;
  border-radius: 0;
  transition: all 0.2s ease-out;

  &--text {
    border: 0;
  }

  &--primary {
    background-color: var(--yps-p-button-bg-color);
    color: var(--yps-p-button-color);
    border: 1px solid var(--yps-p-button-border-color);
    [class^="cars-icon-"] {
      color: var(--yps-p-button-icon-color);
    }

    &:hover {
      background-color: var(--yps-p-button-hover-bg-color);
      color: var(--yps-p-button-hover-color);
      border: 1px solid var(--yps-p-button-hover-border-color);
      [class^="cars-icon-"] {
        color: var(--yps-p-button-hover-icon-color);
      }
    }
  }

  &--secondary {
    background-color: var(--yps-s-button-bg-color);
    color: var(--yps-s-button-color);
    border: 1px solid var(--yps-s-button-border-color);
    [class^="cars-icon-"] {
      color: var(--yps-s-button-icon-color);
    }

    &:hover {
      background-color: var(--yps-s-button-hover-bg-color);
      color: var(--yps-s-button-hover-color);
      border: 1px solid var(--yps-s-button-hover-border-color);
      [class^="cars-icon-"] {
        color: var(--yps-s-button-hover-icon-color);
      }
    }
  }

  &--tertiary {
    background-color: var(--yps-t-button-bg-color);
    color: var(--yps-t-button-color);
    border: 1px solid var(--yps-t-button-border-color);
    [class^="cars-icon-"] {
      color: var(--yps-t-button-icon-color);
    }

    &:hover {
      background-color: var(--yps-t-button-hover-bg-color);
      color: var(--yps-t-button-hover-color);
      border: 1px solid var(--yps-t-button-hover-border-color);
      [class^="cars-icon-"] {
        color: var(--yps-t-button-hover-icon-color);
      }
    }
  }

  &--disabled {
    cursor: not-allowed;
    filter: opacity(0.2);
  }

  &--disabled_dark {
    background: var(--yps-filter-inactive-bg) !important;
    color: var(--yps-filter-inactive-text) !important;
    cursor: not-allowed !important;
  }

  &--small {
    font-size: 0.85em;
    line-height: 1em;
    padding: 4px 9px;
  }

  &--large {
    font-size: 18px;
    padding: 10px 20px;
  }

  &--fullsize {
    width: 100%;
  }
}

.icoButton {
  margin: 0;
  padding: 2px;
  appearance: none;
  cursor: pointer;
  border: none;
  background: none;
  color: var(--yps-text-color);
  font-size: 1em;
  line-height: 1em;
  box-sizing: content-box;
  outline: none;
  text-decoration: none !important;
  transition: all 0.2s ease-out;

  &--round {
    padding: 1px;
    border-radius: 100%;
  }

  &--disabled {
    cursor: not-allowed;
  }

  &--large {
    font-size: 22px;
  }
}

/* ------------------------------------------------------------------------------ FORM INPUTS --- */

.fieldContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  &--focus {
    input {
      outline-style: auto;
      z-index: 10;
    }
  }

  &--horizontal {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    label {
      margin-right: 10px;
      margin-bottom: 0;
      font-size: 1em;
      text-transform: none;
    }
  }

  &--noBorder {
    label {
      margin-right: 0;
    }

    .custom-select {
      &__control {
        border: none;
      }

      &__indicator-separator {
        display: none;
      }
    }
  }

  &--medium {
    input {
      padding: 15px;
      height: 50px;
    }

    .custom-select {
      &__control {
        padding: 8px 7px;
      }
    }
  }

  &--large {
    input {
      padding: 15px 7px;
      height: 50px;
    }
  }
}

.fieldLabel {
  @include input-label();
  margin-bottom: 3px;

  &--error {
    color: var(--yps-danger-color);
  }

  &__requiredStar {
    color: var(--yps-danger-color);
  }

  &.insideLabel {
    font-weight: normal;
    position: absolute;
    top: 3px;
  }
}

.fieldErrors {
  padding: 0 0 1px;
  margin: 0;
  list-style: none;
  background-color: var(--yps-danger-color);
  color: var(--yps-bg-color);
  position: absolute;
  top: 100%;
  z-index: 10;
  left: 0;
  outline: none;

  li {
    padding: 2px 5px;
    font-size: 11px;
    line-height: 16px;
    font-weight: 600;
  }
}

// Text input
.textInput {
  @include input-field();
  transition: all 0.2s ease-out;
  height: 30px;
  padding: 6px;
}

.textareaInput {
  @include input-field();
}

// Checkbox
.checkbox {
  display: none;

  &__container {
    display: flex;
    align-items: center;
    position: relative;
    margin: 10px 0;

    &.checkbox--disabled {
      cursor: not-allowed !important;
    }

    &.fullWidth {
      justify-content: space-between !important;
      width: 100%;
    }
  }

  &__indicator {
    background: var(--yps-bg-color);
    border: 1px solid var(--yps-input-border-color);
    height: 18px;
    width: 18px;
    min-width: 18px;
    min-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &Reversed {
      @extend .checkbox__indicator;
      order: 3;
    }

    &Disabled {
      color: var(--yps-filter-inactive-text) !important;
      background: var(--yps-filter-inactive-bg) !important;
      cursor: not-allowed !important;
    }

    &NotClickable {
      cursor: default !important;
    }

    &Radio {
      border-radius: 100%;
    }

    &Error {
      border-color: var(--yps-danger-color);
    }
  }

  &__tick {
    width: 5px;
    height: 12px;
    margin-bottom: 2px;
    border: 1px solid var(--yps-primary-color);
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    box-sizing: content-box;

    &Radio {
      border: none;
      margin: 0;
      width: 10px;
      height: 10px;
      transform: none;
      border-radius: 100%;
      top: 4px;
      left: 4px;
      background-color: var(--yps-primary-color);
    }
  }

  &__direct_label {
    display: block;
  }

  &__label {
    margin-left: var(--yps-default-space);
    cursor: pointer;
    color: var(--yps-text-color);
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    > span {
      display: flex;
      gap: 5px;
      align-items: center;
    }

    &Reversed {
      @extend .checkbox__label;
      order: 2;
      margin-left: 0;
      margin-right: var(--yps-default-space);
    }

    &NotClickable {
      cursor: default !important;
    }

    &Disabled {
      color: var(--yps-filter-inactive-text) !important;
      cursor: not-allowed !important;
    }
  }
}

// Checkbox
.checkboxButton {
  display: none;

  &__container {
    min-height: 52px;
    color: var(--yps-text-color);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 5px 10px;
    background-color: #fff;
    border: 1px solid var(--yps-input-border-color);
    border-width: 1px;
    border-style: solid;
    margin: 5px 0;
    cursor: pointer;
    font-size: 14px;
    transition: all 0.2s ease-out;

    &--hover {
      filter: drop-shadow(0 0px 10px var(--yps-input-focus-shadow-color));
    }

    &--active {
      border-color: var(--yps-primary-color);
      color: var(--yps-primary-color);
    }
  }

  &notInUse {
    cursor: default !important;
  }

  &__label {
    display: flex;
    justify-content: space-between;
    width: 100%;
    text-align: left;
  }
}

.radioBtn {
  &__container {
    display: flex;
    align-items: center;
    position: relative;

    > *:not(:last-child) {
      margin-right: 15px;
    }

    &--error {
      border: 1px solid var(--yps-danger-color);
      padding: 0 15px;
      height: 50px;
    }
  }
}

// Dropdown
.dropdownValue {
  transition: all 0.2s ease-out;
  z-index: 1;

  &__container {
    background-color: var(--yps-input-bg-color);
    border: var(--yps-input-border-width) solid var(--yps-input-border-color);
    height: 30px;
    margin-left: -1px;
    padding: 6px;
    font-weight: 600;
    font-size: 1em;
    text-align: left;
    line-height: 1.15em;
    display: flex;
    align-items: center;
    transition: all 0.2s ease-out;
    cursor: pointer;
    outline-color: var(--yps-input-outline-color);

    @include mobile {
      margin-bottom: -1px;
    }

    &--medium {
      padding: 15px;
      height: 50px;
    }

    &--large {
      padding: 5px;
      height: 50px;
    }

    &--noBorder {
      padding: 2px;
      border: none;
      box-shadow: none !important;
      outline: none !important;
      background: none !important;
    }

    &--hover {
      filter: drop-shadow(0 0px 10px var(--yps-input-focus-shadow-color));
    }

    &--error {
      border-color: var(--yps-danger-color);
    }

    .icoButton {
      color: var(--yps-dropdown-button-icon-color);
    }
  }

  &__dropdown {
    &Disabled {
      background: var(--yps-filter-inactive-bg) !important;
      span {
        color: var(--yps-filter-inactive-text) !important;
      }
    }
  }

  &__content {
    flex: 1;
    display: flex;
    outline: none;
    align-items: center;
  }

  &__icon {
    color: var(--yps-input-icon-color);
    margin-left: 0;
    margin-right: 7px;
  }

  &__placeholder {
    flex: 1;
    color: var(--yps-text-color-extra-light);
    font-weight: normal;
  }

  &__absolutePlaceholder {
    position: absolute;
    top: 8px;
    left: 15px;
    color: var(--yps-text-color-extra-light);
    font-size: 0.85em;
    font-weight: normal;
  }

  &__value {
    color: var(--yps-text-color-dark);
    flex: 1;

    &--shift {
      transform: translateY(6px);
    }
  }

  &__searchInput {
    font-weight: 600;
    font-size: 1em;
    padding: 0 !important;
    border: none;
    background: none !important;
    outline: none !important;
  }

  &__arrow {
    margin-left: 5px;

    &--medium {
      font-size: 1.1em;
    }

    &--large {
      font-size: 1.2em;
    }
  }
}

.dropdownOptions {
  &__container {
    position: absolute;
    z-index: 100;
    top: 100%;
    left: 0;
    min-width: 100%;
    box-shadow: var(--yps-overlay-shadow);

    &--right {
      left: auto;
      right: 0;
    }
  }

  &__list {
    @include resetList;
  }

  &__item {
    color: var(--yps-input-color);
    appearance: none;
    background-color: var(--yps-bg-color);
    border-width: 1px 0 0;
    border-color: var(--yps-border-color-light);
    text-align: left;
    width: 100%;
    padding: 5px 10px;
    margin: 0;
    cursor: pointer;
    outline: none;
    display: flex;
    align-items: center;
    white-space: nowrap;

    &--medium {
      padding: 10px 15px;
    }

    &--highlight {
      background-color: var(--yps-text-color);
      color: white;
    }

    &--bold {
      font-weight: 600;
    }

    &--current {
      background-color: var(--yps-primary-color);
      color: white;
    }
  }

  &__noResults {
    z-index: 10;
    margin: 0;
    padding: 5px 10px;
    white-space: nowrap;
    color: var(--yps-text-color);
    background-color: var(--yps-bg-color);
  }
}

// Quantity control
.quantityControl {
  display: grid;
  grid-template-columns: 32px minmax(0, auto) 32px;

  &:hover {
    filter: drop-shadow(0 0px 10px var(--yps-input-focus-shadow-color));
  }

  &__label {
    grid-column-start: 1;
    grid-column-end: 4;
    color: var(--yps-text-color-extra-light);
    font-size: 14px;
    margin-bottom: 5px;
  }

  &__input {
    width: 100%;
    text-align: center;
    border-left: none !important;
    border-right: none !important;
  }
}

.list_options {
  &__overlay {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    button {
      width: 44px;
      height: 44px;
      margin: 8px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      &.doubled {
        width: 100px;
      }
    }
  }
}

// Field with icon
.iconField {
  &__container {
    @include input-field();
    padding: var(--yps-input-paddings);
    display: flex;
    align-items: center;
    margin-left: -1px;
    @include mobile {
      margin-bottom: -1px;
    }
    button {
      display: inline-flex;
      align-items: center;
      padding: var(--yps-input-paddings);
      @include mobile {
        padding: var(--yps-input-mobile-paddings);
      }
    }
  }

  &__icon {
    font-size: 1.2em;
    margin-left: 5px;
    color: var(--yps-input-icon-color);
  }

  &__iconEnlarged {
    font-size: 1.9em;
    color: var(--yps-input-icon-color);
    margin-right: 7px;
  }

  &__input {
    @include input-field();
    border: none;
    outline: none;
    padding-left: 5px;
    flex: 1;
  }

  &__inputEnlarged {
    @include input-field();
    border: none;
    outline: none;
    padding: 0;
    @include mobile {
      padding: 0;
    }
    flex: 1;
  }

  &--focused {
    outline-style: auto;
    outline-color: var(--yps-input-outline-color);
    z-index: 10;
  }
}

.dateSelect {
  max-width: 400px;

  &__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    > *:not(:first-child) {
      > div {
        border-left: none !important;
      }
    }

    > *:not(:last-child) {
      > div {
        border-right: none !important;
      }
    }

    &--noDays {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__inputError {
    border-color: var(--yps-danger-color);
  }
}

.row {
  display: flex;
  align-items: center;
  column-gap: 20px;
  row-gap: 20px;
  flex-wrap: wrap;
  margin: 20px 0;

  &--justify {
    > * {
      flex: 1;
    }
  }

  &--column {
    flex-direction: column;
    align-items: stretch;
  }
}

.grid {
  display: grid;
  column-gap: 40px;
  row-gap: 30px;
  margin: 20px 0;

  @include mobile {
    grid-template-columns: 100% !important;
  }
}

.norrisFieldWrapper {
  border: 1px solid var(--yps-input-border-color);
  background-color: var(--yps-input-bg-color);
  font-size: 0;
  height: 30px;
  position: relative;

  &__placeholder {
    height: 100%;
  }

  &__cardLogo {
    position: absolute;
    height: 16px;
    top: 6px;
    right: 6px;
  }

  &--medium {
    height: 50px;
  }
  &--error {
    border-color: var(--yps-danger-color);
  }
}
