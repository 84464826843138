@mixin resetList() {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin resetButton() {
  border: none;
  background: none;
  appearance: none;
}

/* -------------------------------------------------------------------------------------- TEXT--- */

@mixin text-header-lvl-3 {
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
  color: var(--yps-text-color-extra-light);
  margin: var(--yps-default-space) 0;
}

@mixin text-header-lvl-4 {
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  color: var(--yps-text-color-extra-light);
  margin: var(--yps-default-space) 0;
  text-transform: uppercase;
}

/* -------------------------------------------------------------------- GRADIENTS AND SHADOWS --- */

@mixin glow-black($opacity: .1, $radius: 20px) {
  filter: drop-shadow(0 0 $radius rgba(0, 0, 0, $opacity));
}

/* ------------------------------------------------------------------------ RESPONSIVE LAYOUT --- */

@mixin mobile {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin notMobile {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}

@mixin small {
  @media (min-width: 1025px) and (max-width: 1365px) {
    @content;
  }
}


@mixin wide {
  @media (min-width: 1366px) {
    @content;
  }
}

@mixin not-wide {
  @media (max-width: 1365px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1025px) {
    @content;
  }
}

@mixin not-desktop {
  @media (max-width: 1024px) {
    @content;
  }
}


/* ------------------------------------------------------------------------------- CONTAINERS --- */

@mixin limitedContainer {
  width: calc(100% - 20px);
  max-width: var(--yps-max-width);
  margin: var(--yps-default-space) auto 20px auto;
}