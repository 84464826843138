@import '../../assets/scss/abstract/abstract';

.innerContainer {
  max-width: var(--yps-max-width);
  width: calc(100% - 20px);
  background: var(--yps-bg-color-footer);
  margin: 0 auto;
  padding-top: 15px;
}

.outerContainer {
  background: var(--yps-bg-color-footer-wrapper);
}

.title {
  color: var(--yps-text-color-extra-light);
  margin-top: 0;
  font-size: 18px;
  line-height: 32px;
  font-weight: 300;
  text-transform: uppercase;
  border-top: 1px dashed var(--yps-border-color);
  border-bottom: 1px dashed var(--yps-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 0;
}

.subtitle {
  margin: 10px 0;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 400;
  border-bottom: 1px dashed var(--yps-border-color);
}

.detailsContainer {}

.urlParamsList {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 10px 0;
  list-style: none;

  li {
    border: 1px dashed var(--yps-border-color);
    padding: 2px 5px;
    margin-right: 10px;
  }
}

.settingsList {
  padding: 0;
  margin: 10px 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 15px;

  &__flex {
    display: flex;
    align-items: center;
  }
}


.jsonPreview {
  margin: 10px 0;

  &__heading {

  }

  &__data {
    margin-top: 10px;
  }
}

.link {
  color: var(--yps-text-color);
}

.stateSummary {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 40px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: stretch;
  z-index: 1000;
  border-top: 5px solid var(--yps-danger-color);
  filter: drop-shadow(0 0 6px rgba(0, 0, 0, 0.2));

  &--test {
    @extend .stateSummary;
    border-top-color: var(--yps-success-color);
  }

  &__mode {
    color: var(--yps-bg-color);
    font-weight: bold;
    background-color: var(--yps-danger-color);
    padding: 8px 10px;
    height: 100%;
    display: flex;
    align-items: center;

    &--test {
      @extend .stateSummary__mode;
      background-color: var(--yps-success-color);
    }

    &__btn {
      background: none;
      appearance: none;
      cursor: pointer;
      border: none;
      display: flex;
      width: 16px;
      height: 16px;
      padding: 0;
      margin-left: 7px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  &__btn {
    padding: 8px 10px;
    height: 100%;
    display: block;
    background-color: var(--yps-bg-color);
    font-weight: bold;
    text-decoration: none;
    color: var(--yps-text-color-dark);
    transition: all .2s ease;

    &:hover {
      background-color: var(--yps-text-color-dark);
      color: var(--yps-bg-color);
    }
  }
}

.testmodeModal {
  padding: 15px;
  background-color: white;

  &__list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 10px;
    column-gap: 10px;
    column-gap: 10px;
    padding: 0;
    margin: 0 0 20px 0;
  }

  &__btns {

  }
}

.customProxy {
  &__wrapper {
    display: flex;
    align-items: center;
  }
}

.customProxy {
  @include resetList;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 5px;

  &__labelGroup {
    display: flex;
    gap: 5px;
  }
}