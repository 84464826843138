@import "assets/scss/abstract/abstract";

/* ---------------------------------------------------------------------------------- SPINNER --- */

.spinnerContainer {
  margin: 20px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;

  grid-area: spinner;

  &.small {
    width: 30px;
    height: 24px;

    > div {
      width: 4px;
      margin: 0px 1px;
    }
  }
}

.spinnerRectangle {
  height: 100%;
  width: 6px;
  margin: 0 2px;
  display: inline-block;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
  background-color: var(--yps-spinner-color);

  &:nth-child(2) {
    animation-delay: -1.1s;
  }

  &:nth-child(3) {
    animation-delay: -1s;
  }

  &:nth-child(4) {
    animation-delay: -0.9s;
  }

  &:nth-child(5) {
    animation-delay: -0.8s;
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
  }
}

.overlayWrapper {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--yps-overlay-bg-color);
  backdrop-filter: var(--yps-overlay-bg-filter);
  display: flex;
  align-items: center;
  font-family: "Open Sans", Arial, sans-serif;
  
  &__top {
    align-items: flex-start;
  }
  &__bottom {
    align-items: flex-end;
  }
  justify-content: center;

  &--ismobile {
    background: var(--yps-overlay-mobile-bg-color);
    backdrop-filter: none;
    box-shadow: none;
  }

  &__outerContainer {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    display: grid;
    grid-template-rows: auto minmax(0, 1fr);
    @include desktop {
      box-shadow: var(--yps-overlay-shadow);
    }
  }

  &__content {
    max-height: 100%;
    overflow: auto;
    position: relative;
  }

  &__closeBtn {
    @include mobile {
      color: var(--yps-overlay-mobile-head-color) !important;
    }
  }

  &__sidebar {
    background: var(--yps-bg-color);
    padding: 10px;
    .overlayWrapper__head {
      padding: 0;
    }

    .overlayWrapper__content {
      box-shadow: none;
    }

    .overlayWrapper__closeBtn {
      @include mobile {
        color: var(--yps-overlay-head-color) !important;
      }
    }
  }

  &__head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: var(--yps-bg-color);
    color: var(--yps-overlay-head-color);

    @include mobile {
      color: var(--yps-overlay-mobile-head-color);
    }
  }

  &__title {
    font-weight: 300;
    font-size: 28px;
    margin: 0 0 10px;
  }

  &__iframed {
    height: 80vh;
    .overlayWrapper__content {
      overflow: unset;
    }
  }

  &__iframe,
  &__iframe_style_content {
    width: 100%;
    border: 0;
    background: var(--yps-bg-color);
    padding: 10px;
    height: 99%;
  }

  &__iframe_style_content {
    overflow-y: scroll;
  }
}

// Text label
.labelledText {
  &__label {
    color: var(--yps-text-color-extra-light);
    font-size: 11px;
    text-transform: uppercase;
  }

  p,
  span,
  i {
    margin: 0;
    line-height: 14px;
  }
}

// Tabs
.tabs {
  overflow: hidden;

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: grid;

    @include tablet {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) !important;
    }

    @include mobile {
      grid-template-columns: 1fr !important;
    }

    li {
      button {
        width: 100%;
        padding: 10px 5px;
      }
      @include desktop {
        &:not(:last-child) {
          button {
            &:not(:hover) {
              border-right-color: transparent;
          }
        }
        }
      }
    }
  }

  &__content {
    padding: var(--yps-default-space);
  }
}

.supplier {
  line-height: 90%;
}

.line_between {
  &.supplier {
    > span {
      margin-top: -1px;
      border-top: 1px solid var(--yps-text-color);
    }
  }

  &.partner {
    > span {
      border-bottom: 1px solid var(--yps-text-color);
    }
  }
}

.partnerLogo {
  &Container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    @include mobile {
      justify-content: flex-end;
      align-items: baseline;
    }
    flex-wrap: wrap;
    img {
      font-weight: bold;
    }
  }
  &Name {
    font-weight: bold;
    line-height: 1.5rem;
  }

  &Label {
    line-height: 1.5rem;
    font-size: small;
    padding-right: 5px;
    display: block;
    white-space: nowrap;
    color: var(--yps-text-color-extra-light);
  }
}

.carIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;

  &__label {
    font-size: 13px;
    margin-left: 10px;
  }

  border-radius: 50%;
  padding: 4px;
}

.tickList {
  list-style: none;
  padding: 0;

  &__item {
    display: flex;
    align-items: flex-start;
  }

  &__icon {
    margin-right: var(--yps-default-space);
    color: var(--yps-success-color);

    &--circled {
      border: 2px solid var(--yps-success-color);
    }
  }
}

.icoLabel {
  display: flex;
  align-items: center;
  margin: 9px 0;
  flex-direction: row-reverse;

  &__content {
    display: flex;
    white-space: nowrap;
    flex-direction: row-reverse;
    align-items: baseline;
    gap: 5px;
    margin: 0 0 0;
  }

  &__title {
    font-weight: 600;
  }

  &__subtitle {
    font-size: 13px;
    line-height: 12px;
    font-weight: 400;
  }

  &__icon {
    color: var(--yps-text-color-extra-light);
  }

  [class^="cars-icon-"]:before {
    color: var(--yps-text-color);
  }
}

.progressBar {
  height: 3px;
  position: relative;
  background-color: var(--yps-border-color-light);

  &__indicator {
    height: 100%;
    transition: all 0.3s ease;
    background-color: var(--yps-progress-indicator);
  }
}

.floatingContent {
  position: relative;

  &__content {
    position: sticky;
    top: 0;

    &--iframe {
      @media (min-width: 900px) {
        position: absolute;
      }
    }
  }
}


.infobox {
  padding: 10px;
  display: flex;
  gap: 10px;
  line-height: 1.5rem;
  border: 1px solid transparent;
  margin-bottom: 10px;
  align-items: flex-start;
  &:last-child {
    margin-bottom: 0;
  }

  > div {
    width: 100%;
  }

  .titleSection {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
  }

  .box__is_closed {
    display: none;
  }

  h3 {
    padding: 0;
    margin: 0 0 5px;
    font-weight: 500;
  }

  [class^="cars-icon-"] {
    align-items: flex-start;
  }

  &__error {
    background: var(--yps-infobox-background-error);
    color: var(--yps-infobox-text-error);
    [class^="cars-icon-"] {
      color: var(--yps-infobox-icon-error);
    }
    border-color: var(--yps-infobox-border-error);

    h3 {
      color: var(--yps-infobox-heading-error);
    }
  }

  &__info {
    background: var(--yps-infobox-background-info);
    color: var(--yps-infobox-text-info);
    border-color: var(--yps-infobox-border-info);
    [class^="cars-icon-"] {
      color: var(--yps-infobox-icon-info);
    }

    h3 {
      color: var(--yps-infobox-heading-info);
    }
  }

  &__default {
    background: var(--yps-infobox-background-default);
    color: var(--yps-infobox-text-default);
    [class^="cars-icon-"] {
      color: var(--yps-infobox-icon-default);
    }
    border-color: var(--yps-infobox-border-default);

    h3 {
      color: var(--yps-infobox-heading-default);
    }
  }

  &__warning {
    background: var(--yps-infobox-background-warning);
    color: var(--yps-infobox-text-warning);
    [class^="cars-icon-"] {
      color: var(--yps-infobox-icon-warning);
    }
    border-color: var(--yps-infobox-border-warning);

    h3 {
      color: var(--yps-infobox-heading-warning);
    }
  }

  &__success {
    background: var(--yps-infobox-background-success);
    color: var(--yps-infobox-text-success);
    [class^="cars-icon-"] {
      color: var(--yps-infobox-icon-success);
    }
    border-color: var(--yps-infobox-border-success);

    h3 {
      color: var(--yps-infobox-heading-success);
    }
  }

  &__neutral {
    background: var(--yps-bg-color);
    color: inherit;
    [class^="cars-icon-"] {
      color: var(--yps-infobox-icon-neutral);
    }
    border: 1px solid var(--yps-border-color-light);

    h3 {
      color: var(--yps-infobox-heading-neutral);
    }
  }
}

.checkboxAlignTop {
  align-items: flex-start !important;
}

.openHours {
  &__day {

    &__wrapper {
      display: inline-block;
    }

    &__label {
      color: var(--yps-text-color-extra-light);
      display: block;

      &--inline {
        display: inline-block;
        margin-right: 5px;
      }
    }

    &__weekday {
      margin-right: 5px;
    }
  }
}

.creditCardLogos {
  display: flex;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
  }

  > *:not(:last-child) {
    margin-right: 10px;
  }

  img {
    height: 24px;
    filter: grayscale(1);
    opacity: 0.4;
    transition: all 0.3s ease-out;

    &:hover {
      filter: grayscale(0);
      opacity: 1;
    }
  }
}

.dynamicBackground {
  &__bg {
    position: fixed;
    max-height: 100%;
    z-index: -2;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    &__img {
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: cover !important;
      background-repeat: no-repeat;
      visibility: visible;
      opacity: 1;
      z-index: -1;
      transition: visibility 2s, opacity 2s linear;

      &_secondary {
        z-index: -2;
      }
    }

    &__useSecondary {
      .dynamicBackground__bg__img_secondary {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  &__wrapper {
    @include limitedContainer;
    background-color: var(--yps-bg-color);

    @include mobile {
      width: 100%;
    }
  }
}

.link {
  color: var(--yps-general-links-color);
  &:hover {
    color: var(--yps-general-links-hover-color);
  }

  &__underlined {
    text-decoration: underline;
  }
}


.errorBoundary {
  @include limitedContainer;
  text-align: center;

  .title {
    font-weight: 300;
    margin: 0 0 20px 0;
  }
}

.countdown {
  background-color: var(--yps-success-color);
  color: var(--yps-bg-color);
  margin-bottom: 15px;
  padding: 15px 20px;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--yps-box-radius-floating-content);
  &__label {
    margin-right: 5px;
  }

  &__value {
    font-weight: 600;
    font-size: 1.2em;
    margin-top: 5px
  }

  &--warning {
    background-color: var(--yps-warn-color);
  }

  &--error {
    background-color: var(--yps-danger-color);
  }
}
