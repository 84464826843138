@import "../../assets/scss/abstract/abstract";

.header {
  padding: 5px 0;
  transition: all 0.3s ease;

  &__bar {
    width: calc(100% - 20px);
    max-width: var(--yps-max-width);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile {
      max-width: 100vw;
      width: 100%;
      padding: 0 10px;
      margin: 0;
    }
  }

  &--enlarged {
    padding: 25px 0;

    @include mobile {
      padding: 0;
    }
  }

  &--booking {
    @include mobile {
      // padding-top: 75px;
    }
  }
}



.languageSelect {
  &__value {
    box-shadow: 0 0 6px rgb(0 0 0 / 10%);
    border-radius: 100%;
    object-fit: cover;
    object-position: center;
    margin-left: 3px;
  }

  &__row {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      box-shadow: 0 0 6px rgb(0 0 0 / 20%);
      border-radius: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

.topStickyContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0;
  z-index: 2000;

  &__content {
    width: var(--yps-max-width);
    max-width: 100%;
    margin: 0 auto;
  }
}

.steps {
  margin: 20px auto;
  width: calc(100% - 20px);
  max-width: var(--yps-max-width);
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  list-style: none;
  background: var(--yps-steps-wrapper-bg-color);

  @include mobile {
    max-width: 100vw;
    width: 100%;
    padding: 0 10px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 1px;
    top: 50%;
    left: 10px;
    right: 10px;
    background-color: var(--yps-border-color-light);
  }

  &__number {
    display: inline-block;
    vertical-align: middle;
    padding: 0px;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 13px;
    text-align: center;
    color: var(--yps-steps-item-number-color);
    border: 1px solid var( --yps-steps-item-number-border-color);
    border-radius: 100%;
    margin-right: 10px;

    @include mobile {
      margin: 0 10px;
      margin-bottom: 5px;
    }

    &--highlight {
      color: var(--yps-steps-item-number-highlight-color);
      border-color: var(--yps-steps-item-number-highlight-border-color);
    }
  }

  &__icon {
    display: inline-block;
    vertical-align: middle;
    padding: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    font-size: 13px;
    text-align: center;
    color: var(--yps-steps-item-icon-color);
    border: 1px solid var(--yps-steps-item-icon-border-color);
    border-radius: 100%;
    margin-right: 10px;

    @include mobile {
      margin-right: 0;
      margin-bottom: 5px;
    }

    &--highlight {
      color: var(--yps-steps-item-icon-highlight-color);
      border-color: var(--yps-steps-item-icon-highlight-border-color);
    }
  }

  &__item {
    padding: 0;
    background-color: var(--yps-steps-item-bg-color);
    position: relative;

    &__btn {
      @include resetButton;
      color: var(--yps-steps-item-text-color);
      text-decoration: none;
      padding: 0 var(--yps-default-space);

      @include mobile {
        padding: 0;
      }

      &--highlight {
        color: var(--yps-steps-item-text-highlight-color);
      }
    }

    &:first-child {
      button {
        padding-left: 0;
      }
    }

    &:last-child {
      button {
        padding-right: 0;
      }
    }
  }
}
