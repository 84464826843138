@use "sass:math";
@import "../../assets//scss/abstract/abstract";

.compareBtn {
  font-size: 12px;
}

.compareBar {
  grid-area: compareBar;
  display: flex;
  justify-content: stretch;
  overflow-x: auto;
  align-items: center;
  padding: var(--yps-default-space);
  column-gap: 30px;

  &__buttons {
    display: flex;
    @include mobile {
      width: 100%;
    }
    button {
      width: 100%;
      &:nth-child(2) {
        margin-left: 5px;
        width: 80%;
      }
    }
    margin-top: 10px;
    justify-content: space-between;
  }

  &__wrapper {
    &_container {
      position: relative;
    }

    &_content {
      background: var(--yps-compare-mobile-overlay);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      [class^="cars-icon-"] {
        width: 80px;
        height: 80px;
        color: var(--yps-compare-mobile-not-added-icon-color);
        background: var(--yps-compare-mobile-not-added-icon-background);
      }
    }
  }

  &__inCompare {
    border: 2px solid var(--yps-compare-mobile-border);
    [class^="cars-icon-"] {
      color: var(--yps-compare-mobile-added-icon-color);
      background: var(--yps-compare-mobile-added-icon-background);
    }
  }

  &.isMobile {
    flex-direction: column;
    overflow-x: hidden;
  }

  &.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    background: var(--yps-bg-color);
    z-index: 100;
  }

  &__empty {
    background: var(--yps-border-color-light);
    border-radius: 0;
    color: var(--yps-text-color-extra-light);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__main {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 20;
    width: 100%;
    height: 80px;
  }

  &__list {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: var(--yps-default-space);
    flex: 1;
    height: 100%;

    li {
      flex-basis: 0;
      flex-grow: 1;
      max-width: 33%;
      height: 100%;
      > * {
        height: 100%;
      }
    }
  }

  &__head {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    h4 {
      margin: 0 0 5px;
    }
    span {
      margin: 0 0 5px;
    }
  }

  &__car {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    padding: 5px;
    background: var(--yps-bg-color);

    &.isMobile {
      border: 2px solid var(--yps-input-icon-color);
      border-radius: 0;
      width: 100%;
    }

    &:not(:last-child) {
      margin-right: 30;

      &:after {
        content: "";
        position: absolute;
        display: block;
        background-color: var(--yps-border-color);
        right: 0;
        top: 5px;
        bottom: 5px;
        width: 1px;
        flex: none;
      }
    }
  }

  &__carName {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 150px;
    font-weight: 600;
    font-size: small;
    text-overflow: ellipsis;
  }

  &__price {
    font-weight: 600;
    color: var(--yps-featured-price);

    .prefix {
      font-weight: 400;
      color: var(--yps-text-color);
    }
  }

  &__offerPicture {
    padding: 5px;
    background: var(--yps-bg-color);
  }

  &__carDetails {
    display: flex;
    flex-direction: column;
    line-height: 1.2em;
  }

  &__remove {
    margin-left: auto;
  }
}

.comparison {
  width: var(--yps-max-width) !important;

  &__title {
    font-weight: 300;
    font-size: 28px;
    margin: 0 0 var(--yps-default-space);
  }

  &__table {
    width: 100%;
    background-color: var(--yps-bg-color);
    padding: 20px var(--yps-default-space);

    &__row {
      display: flex;
      align-items: flex-start;
      @include mobile {
        flex-direction: column;
      }
    }

    &__label {
      width: 15%;
      font-weight: 600;
      color: var(--yps-text-color-extra-light);
      @include mobile {
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 400;
        width: 100%;
        line-height: 2em;
      }
    }

    &__rowContent {
      flex: 1;
      width: 100%;
      display: flex;
      align-items: flex-start;
      margin-bottom: var(--yps-default-space);
      position: relative;
      column-gap: 20px;

      &:not(.noDivider) {
        padding-bottom: 15px;
        margin-bottom: 15px;

        &:after {
          content: "";
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: var(--yps-border-color-light);
        }
      }
    }

    &__cell {
      flex: 1;

      p {
        margin: 0;
      }
    }
  }

  &__carName {
    margin: 0;
    font-size: 16px;
    line-height: 16px;
  }

  &__orSimiliar {
    color: var(--yps-text-color-extra-light);
    font-weight: normal;
    margin-left: 6px;
  }

  &__offerPicture {
    @include mobile {
      max-width: 100px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  &__partnerLogoContainer {
    > span {
      justify-content: flex-start;
    }
  }

  .bookBtn {
    text-align: right;
    display: flex;
    align-items: center;
    padding: 5px 10px;

    &__total {
      font-size: 20px;
      line-height: 20px;
      font-weight: 500;
    }

    &__perDay {
      display: block;
      font-size: 13px;
      line-height: 18px;
      font-weight: 500;
      white-space: nowrap;
      display: flex;
      opacity: 0.7;
    }
  }
}
